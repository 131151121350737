<template>
  <div name="overview">
    <div class="ml-6">
      <p class="font-semibold text-lg" v-html="course.description.String"></p>

      <ul class="mt-6 mb-12">
        <li
          v-for="lesson in orderedLessons"
          :key="lesson.id"
          @click="showLesson(lesson)"
          class="cursor-pointer hover:opacity-75"
        >
          <h4
            class="
              w-11/12
              text-gray-600
              font-bold
              text-basel
              border-b border-gray-200 border-solid
              pb-1
              text-base
            "
          >
            {{ lesson.name }}
          </h4>

          <div class="flex justify-center items-center">
            <div class="flex-1">
              <p
                class="mt-3 mb-4 text-sm unit-content"
                v-html="lesson.outline.String"
              ></p>
            </div>
            <div class="flex-none w-20 mr-10">
              <i class="fas fa-chevron-right opacity-25 pt-4"></i>
            </div>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: "overview",
  props: {
    course: Object,
    lessons: Array,
  },
  computed: {
    orderedLessons() {
      return this.$orderBy(this.lessons, ["order", "name"]);
    },
  },
  methods: {
    showLesson(lesson) {
      this.$emit("showLesson", lesson);
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
